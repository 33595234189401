import React from 'react'
import PropTypes from 'prop-types'
import { IconWithTextPropType } from '../propTypes'

const ContactFormIcons = ({ icons }) =>
  icons.map(icons =>
    <div className='contact-form-icon'>
      <img
        src={icons.image.url}
        alt={icons.image.alternativeText}
      />
      <div>
        <div className='contact-form-icon__header'>{icons.header}</div>
        <div className='contact-form-icon__description'>{icons.description}</div>
      </div>
    </div>
  )

export default ContactFormIcons

ContactFormIcons.propTypes = {
  icons: PropTypes.arrayOf(IconWithTextPropType)
}

ContactFormIcons.defaultProps = {
  icons: []
}
