import React from 'react'
import Layout from '../components/Layout/Layout'
import { graphql } from 'gatsby'
import ContactForm from '../components/ContactForm/ContactForm'

const ContactFormPage = ({ data }) =>
  <Layout description='Kontakt formular'>
    <ContactForm {...data.strapiContactForm} />
  </Layout>

export default ContactFormPage

export const query = graphql`
  query ContactFormPageQuery {
  ...contactFormFragment
  }
 `
