import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import ContactFormIcons from './ContactFormIcons'
import PropTypes from 'prop-types'
import { IconWithTextPropType } from '../propTypes'
import FormWrapper from '../FormWrapper/FormWrapper'
import JotformEmbed from 'react-jotform-embed'

const ContactForm = ({ header, description, icons }) =>
  <SectionWrapper className='contact-form-container'>
    <div className='contact-form-container-text'>
      <div className='contact-form-container-text__header'>{header}</div>
      <div className='contact-form-container-text__description'>{description}</div>
    </div>
    <FormWrapper
      leftSide={<ContactFormIcons icons={icons} />}
      rightSide={<JotformEmbed src='https://form.jotform.com/220411289014041' />}
    />
  </SectionWrapper>

export default ContactForm

ContactForm.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  icons: PropTypes.arrayOf(IconWithTextPropType)
}

ContactForm.defaultProps = {
  header: '',
  description: '',
  icons: []
}
